import { Box, Chip, Modal, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { AiFillCheckCircle, AiFillCloseSquare } from "react-icons/ai";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import useAddStorage from "../../Hooks/useAddStorage";

type Props = {
  open: boolean;
  handleClose: () => void;
  storageItemsFromApi: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  overflowY: "scroll",
  borderRadius: "24px"
};

const StyledContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, minmax(240px, 1fr) )",
  gap: "1.5rem",
  backgroundImage: "linear-gradient(198deg, rgba(2,0,36,1) 0%, rgba(90,66,119,1) 100%, rgba(206,175,245,1) 100%)",
  boxShadow: "0px -2px 17px #b966b6c2",
  borderRadius: "24px",

  padding: "2rem",
  "@media (max-width: 1650px)": {
    gridTemplateColumns: "repeat(2, minmax(260px, 1fr) )",
  },
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "repeat(2, minmax(200px, 1fr) )",
  },
  "@media (max-width: 700px)": {
    gridTemplateColumns: "1fr",
  },
});

const StoragesModal = ({ open, handleClose, storageItemsFromApi }: Props) => {
  const { i18n } = useTranslation();

  const cartState = useSelector((state: any) => state.cart);

  const cookies = new Cookies();

  const [storageItemsAfterFiltering, setStorageItemsAfterFiltering] = useState<any>([]);

  useEffect(() => {
    setStorageItemsAfterFiltering(
      storageItemsFromApi.filter((item: any) => {
        return item?.actual_size > cartState.totalActualSize;
      })
    );
  }, [storageItemsFromApi]);

  const { loading, handleAddStorage } = useAddStorage();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-cart-modal"
      aria-describedby="modal-add-to-cart-modal"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box
        sx={{
          ...style,
          maxHeight: {
            xs: "80vh",
            lg: "unset",
          },
          width: "100%",
          "@media (min-width: 1023.1px)": {
            maxWidth: "70vw",
          },
          "@media (max-width: 1023px)": {
            maxWidth: "92vw"
          }
        }}
      >
        <StyledContainer>
          {storageItemsAfterFiltering?.map((item: any) => {
            if (item.id === cartState.store_items?.id) {
              return (
                <Chip
                  disabled={loading}
                  sx={{
                    flexDirection: "row-reverse",
                    justifyContent: "center",
                    padding: "0 16px",
                    height: "48px",
                    whiteSpace: "nowrap"
                  }}
                  icon={
                    <>
                      <Box
                        component={"span"}
                        sx={{
                          color: "#FFAD0A !important",
                        }}
                      >
                        {`(${Number(item.price).toLocaleString()} ${i18n.language === "ar" ? cookies.get("currency_ar") || "دينار كويتى" : cookies.get("currency_en") || "KWT"})`}
                      </Box>
                    </>
                  }
                  onClick={() => {
                    // dispatch(cartActions.removeStorageItem(item?.id));
                  }}
                  color="primary"
                  label={i18n?.language === "ar" ? item?.name_ar : item?.name}
                />
              );
            } else {
              return (
                <Chip
                  disabled={loading}
                  onClick={() => {
                    handleAddStorage(item);
                  }}
                  sx={{
                    height: "48px",
                    color: "#ffffff80",
                    border: "1px solid #ffffff80",
                    flexDirection: "row-reverse",
                    justifyContent: "space-around",
                    padding: "0 16px",
                    maxHeight: "unset",
                  }}
                  label={i18n?.language === "ar" ? item?.name_ar : item?.name}
                  //add small span with price
                  icon={
                    <Box
                      component={"span"}
                      sx={{
                        color: "#FFAD0A !important",
                      }}
                    >
                      {`(${Number(item.price).toLocaleString()} ${i18n.language === "ar" ? cookies.get("currency_ar") || "دينار كويتى" : cookies.get("currency_en") || "KWT"})`}
                    </Box>
                  }
                />
              );
            }
          })}
        </StyledContainer>
      </Box>
    </Modal>
  );
};

export default StoragesModal;
