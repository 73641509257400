// @ts-nocheck
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import StyledButton from "../../StyledComponents/StyledButton/StyledButton";
import MainTextField from "../MainTextField/MainTextField";
import { createData } from "../../../Services/DataServices";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../Helpers/toastHelper";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {};

const RequestForm = (props: Props) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState([]);
  // const [previewSource, setPreviewSource] = useState(null);
  const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files);

    setImages((prevImages) => {
      const uniqueImages = newImages.filter(newImage => !prevImages.some(prevImage => prevImage.name === newImage.name && prevImage.size === newImage.size));

      if (prevImages.length + uniqueImages.length > 10) {
        return [...prevImages, ...uniqueImages.slice(0, 10 - prevImages.length)];
      }

      return [...prevImages, ...uniqueImages];
    });
  };
  const handleDeleteImage = (index, e) => {
    e.preventDefault();
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const handleRequestForm = async (data: any) => {
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`images[${index}]`, image, image.name); // Important: Use array-like keys
    });
    // formData.append('file', images as File); 
    for (let key in data) {
      formData.append(key, data[key]);
    }
    try {
      setSubmitting(true);
      await createData("special_request/create", formData).then((res) => {
        if (res?.status === 200) {

          displayToastSuccess(res?.data?.message);
        } else {
          displayToastErrorApi(res?.data?.message);
        }
      });
    } catch (error: any) {
      displayToastErrorApi(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };
  const validationSchema = yup.object().shape({
    file: yup.string(),
    name: yup.string().required(t("requestForm.validation.name")),
    email: yup
      .string()
      .required(t("requestForm.validation.email"))
      .email(t("requestForm.validation.email")),
    phone: yup
      .string()
      .required(t("requestForm.validation.phone"))
      .min(8, t("requestForm.validation.phone")),
    request: yup
      .string()
      .required(t("requestForm.validation.request"))
      .min(10, t("requestForm.validation.request")),
  });
  const {
    handleSubmit,
    register,
    setError,
    control,
    reset,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const inputs = [
    {
      name: "name",
      label: t("requestForm.name"),
    },
    {
      name: "email",
      label: t("requestForm.email"),
    },
    {
      name: "phone",
      label: t("requestForm.phone"),
    },
    {
      name: "request",
      label: t("requestForm.request"),
      multiline: true,
    },
  ];
  return (

    <Stack
      component="form"
      spacing={2}
      sx={{
        "& > :last-child": {
          marginTop: "4rem",
        },
      }}
    >
      {inputs.map((input, index) => (
        <MainTextField
          background={"#81cefc33"}
          key={index}
          {...input}
          control={control}
        />
      ))}
      <label
        htmlFor="img"
        style={{ width: '200px', height: '200px', borderRadius: '20px', overflow: 'hidden', display: 'flex', marginBottom: '12px', backgroundColor: '#444874', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }} >
        <>
          <input
            type="file"
            // {...register("file")} 
            onChange={handleImageChange}
            id="img"
            multiple
            style={{ position: 'absolute', visibility: 'none', opacity: '0' }}
          />
          <Typography
            sx={{
              color: "#d7b5ff",
              textAlign: "center",
              cursor: "pointer"
            }}
            variant="h6"
          >
            {t("image_file")}
          </Typography>
        </>

      </label>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          flexWrap: "wrap"
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="image-wrapper"
            style={{ position: "relative", borderRadius: '20px', overflow: 'hidden', display: 'flex', marginBottom: '12px', backgroundColor: '#444874', cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }}>
            <img src={URL.createObjectURL(image)} alt={`preview-${index}`} className="image" style={{ display: "inline-block", width: "200px", height: "200px", objectFit: 'cover' }} />
            <button
              onClick={(e) => handleDeleteImage(index, e)}
              className="delete-btn"
              style={{ position: "absolute", fontSize: "18px", fontWeight: "600", borderRadius: "4px", top: "12px", right: "12px", background: "#3A9FD8", color: "#fff", width: "25px", height: "25px", cursor: "pointer" }}
            >
              X
            </button>
          </div>
        ))}
      </Box>


      <StyledButton
        type="submit"
        sx={{
          alignSelf: "flex-end",
        }}
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleSubmit(handleRequestForm)}
        disabled={submitting}
        endIcon={submitting && <CircularProgress size={20} />}
      >
        {t("requestForm.send")}
      </StyledButton>
    </Stack>
  );
};

export default RequestForm;
