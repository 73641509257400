import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import fallbackImage from "../../../Assets/Images/default-movie.jpg";
import { getSmallerText } from "../../../Helpers/Functions/getSmallerText";
import useAddToCart from "../../../Hooks/useAddToCart";
import useLongPress from "../../../Hooks/useLongPress";
import { movie } from "../../../Types/Types";
import { addToCartModalActions } from "../../../store/addToCart-slice";
import TiltAnimation from "../../Animation/TiltAnimation";
import FavIcon from "../FavIcon/FavIcon";
import Rating from "../Rating/Rating";
import "./MainCard.scss";

const MainCard = ({
  id,
  name,
  name_ar,
  poster,
  discount_percentage,
  type,
  avg_rate,
  is_favorite,
  price,
  poster_thumb,
  is_available
}: movie) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { handleAddToCart, loading } = useAddToCart();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const openAddToCartModal = () => {
    // if (!cookies.get("token")) {
    //     navigate("/login");
    //   } else {
    //     let info = {
    //       movieId: id,
    //       type: type,
    //       price: price,
    //       is_available: is_available
    //     };
    //     dispatch(addToCartModalActions.openAddToCartModal(info));
    //   }
    let info = {
      movieId: id,
      type: type,
      price: price,
      is_available: is_available
    };
    dispatch(addToCartModalActions.openAddToCartModal(info));
  };
  const isMobile = useMediaQuery("(max-width:650px)");
  const onLongPress = () => {};

  const onClick = () => {
    if (type === "episode") {
      return;
    } else {
      navigate(`/media/${type?.toLowerCase()}/${id}`);
    }
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 200,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  // const handleMouseMove = () => {
  //   if (isDragging) {
  //     setIsDragging(false);
  //   } else {
  //     setIsDragging(true);
  //   }
  // };

  const handleClick = () => {
    if (type === "episode") {
      return;
    } else {
      navigate(`/media/${type?.toLowerCase()}/${id}`);
    }
  };

  return (
    <Box
      sx={{
        height: {
          mobile: "460px",
          lg: "540px",
        },
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <TiltAnimation>
        <Box
          sx={{
            width: {
              mobile: "200px",
              lg: "260px",
            },
            height: {
              mobile: "420px",
              xl: "490px",
            },
            backgroundColor: "transparent",
            margin: {
              xs: "0px auto",
              sm: "5px auto",
              md: "20px auto",
            },
          }}
        >
          <div className={loading ? "main-card loading" : "main-card"}>
            <FavIcon id={String(id)} type={type} is_favorite={is_favorite} />
            <div className="main-card-image">
              <div className="card-overlay"></div>
              {discount_percentage && <div className="offer-percent">{discount_percentage}</div>}
              <img
                // {(isMobile === false) && {...longPressEvent}}
                // {...!isMobile && {...longPressEvent}}
                {...(!isMobile && longPressEvent)}
                // onClick={() => {!isMobile && onClick()}}
                onDoubleClick={() => {isMobile && onClick()}}
                onError={(e) => {
                  if (e.currentTarget.src !== poster) {
                    e.currentTarget.src = poster;
                  } else {
                    e.currentTarget.src = fallbackImage;
                  }
                }}
                src={`${poster_thumb}`}
                alt={name}
              />
            </div>
            <div
              className="main-card-footer"
              style={{
                padding: getSmallerText(name, 50)
                  ? "4px 10px 0 10px"
                  : getSmallerText(name, 18)
                  ? "0px 10px 0 10px"
                  : "14px 10px 0 10px",
              }}
            >
              <Box
                component={"h4"}
                sx={{
                  fontSize: {
                    mobile: getSmallerText(name, 50) ? "12px" : getSmallerText(name, 40) ? "14px" : "18px",
                    xl: getSmallerText(name, 50) ? "16px" : getSmallerText(name, 30) ? "18px" : "22px",
                  },
                }}
              >
                {name}
              </Box>

              <Button
                sx={{
                  width: {
                    mobile: "100px",
                    sm: "120px",
                    lg: "153px",
                  },
                  height: {
                    mobile: "40px",
                    lg: "50px",
                  },
                  borderRadius: "8px",
                  backgroundImage: "linear-gradient(180deg, #81CEFC 0%, #3DB9EC 100%)",
                  color: "white",
                  fontSize: {
                    mobile: "20px",
                    md: "24px",
                  },
                  position: "absolute",
                  top: "-35px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  opacity: "0",
                  transition: "all 0.5s ease-in-out",
                  "&.Mui-disabled": {
                    backgroundColor: "#3DB9EC",
                    backgroundImage: "none",
                  },
                }}
                variant="contained"
                onClick={() => {
                  openAddToCartModal();
                  // handleAddToCart(type, id, 1);
                }}
              >
                {t("buy")}
              </Button>

              {/* <Rating avg_rating={avg_rate} type={type} id={id} /> */}
            </div>
          </div>
        </Box>
      </TiltAnimation>
    </Box>
  );
};

export default React.memo(MainCard);
