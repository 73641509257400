import { Box } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import LinearGradient from "../StyledComponents/LinearGradient/LinearGradient";
import FooterInfo from "./Footer/FooterInfo/FooterInfo";
import Navbar from "./Navbar/Navbar";
import ScrollToTop from "../Blocks/Buttons/ScrollToTop";
import useGetData from "../../Hooks/useGetData";
import { useTranslation } from "react-i18next";
import WhatsApp from "../Blocks/WhatsApp/WhatsApp";

type Props = {};

const Layout = (props: Props) => {
  const { data } = useGetData("settings/all", []);
  const { i18n } = useTranslation();
  const [logo, setLogo] = useState();
  const updatedLogo = data && data?.settings?.logo;
  const copyRights = data && data?.settings;

  const location = useLocation();
  const pathname = location.pathname;

  console.log("Current Pathname:", pathname); 

  return (
    updatedLogo && (

      <Box>
        <ScrollToTop/>
        <LinearGradient />
        <Navbar />
        <Outlet />
        <Box sx={{ height: pathname === "/request" ? "auto" : "50vh", width: "100%" }}>
          <FooterInfo logo={updatedLogo} rights={i18n.language === "ar" ? copyRights?.copyrights_ar : copyRights?.copyrights_en } />
        </Box>
        <WhatsApp/>
      </Box>
    )  
  );
};
//  className = "layout";

export default React.memo(Layout);
