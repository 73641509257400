import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../Helpers/toastHelper";
import { createData } from "../../../Services/DataServices";
import { cartActions } from "../../../store/cart-slice";
import CheckoutModal from "../CheckoutModal/CheckoutModal";
import { Link, useNavigate } from "react-router-dom";
import useGetData from "../../../Hooks/useGetData";
import Cookies from "universal-cookie";

const OrderComponent = () => {
  const cartState = useSelector((state: any) => state.cart);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [totalUsd, setTotalUsd] = useState('')
  const { data, error } = useGetData("settings/all", []);
  const confirmMessage = data && data?.settings
  
  const handleOpen = () => setShowConfirm(true);
  const handleClose = () => setShowConfirm(false);

  useEffect(() => {
    handleClose()
  }, [])

  const prepareFormData = () => {
    let formData = new FormData();
    // ... logic to prepare formData

    formData.append("address_id", cartState?.address_id || "");
    formData.append("payment_method", String(cartState?.payment_method));
    formData.append("client_note", cartState?.client_note);
    // Append priceTypes as an array directly
    cartState?.items?.forEach((item: any, index: number) => {
      formData.append(`items[${index}][id]`, item.id);
      formData.append(`items[${index}][price_type]`, item.price_type);
      formData.append(`items[${index}][has_cover]`, item.has_cover);
    });
    // Append storage items as an array directly
    if (cartState?.store_items?.id) {
      formData.append("storage_id", cartState?.store_items?.id);
      cartState?.storage_items?.forEach((item: any, index: number) => {
        formData.append(`storage_ids[${index}]`, item.id);
      });
    }
    return formData;
  };

  const handleSubmitOrder = async () => {
    try {
      setLoading(true);
      const formData = prepareFormData();
      const response = await createData("order/create-from-cart", formData);
      // console.log('usd',response.data.Data);
      setTotalUsd(response?.data?.Data?.total_cost_usd)
      if (cartState?.payment_method === 1) {
        dispatch(cartActions.clearCart());
        displayToastSuccess(response?.data?.message);
        navigate("/purchases");
      }
      if (response.status === 200) {
        if (cartState?.payment_method === 4 && response?.data?.IsSuccess) {
          dispatch(
            cartActions.setUPaymentUrl(response?.data?.Data)
          );
          if (cartState?.payment_method !== 4) {
            dispatch(cartActions.handleOpenCheckOutModal());
          }
        }

        return response;
      } else {
        displayToastErrorApi(response?.data?.message);
      }
    } catch (error: any) {
      // console.log(error);
      displayToastErrorApi(
        error?.response?.data?.errors ||
          error?.data?.message ||
          error?.response?.data?.message
      );
      // handleClose();
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleSubmitLastStep = async () => {
    if (cartState?.payment_method === 3) {
      dispatch(cartActions.handleOpenCheckOutModal());
      return;
    } else {
      handleSubmitOrder();
    }
  };



  return (
    <>
      <SubmitButton
        onClick={() => {
          if(cartState?.payment_method === 4) {
            handleSubmitLastStep()
            handleOpen()
          } else {
            handleOpen()
          }
        }}
        // onClick={handleOpen}
        loading={loading}
        label={t("purchases.payment.next")}
      />
      <CheckoutModal handleSubmitOrder={handleSubmitOrder} total_usd={totalUsd} />

      <ConfirmDialog
        open={showConfirm}
        onClose={handleClose}
        onConfirm={handleSubmitLastStep}
        loading={loading}
        title={i18n.language === "ar" ? confirmMessage?.confirm_message_ar : confirmMessage?.confirm_message_en}
        confirmLabel={t("notAvailable.confirm")}
        cancelLabel={t("notAvailable.cancel")}
        paymenyMethod={4}
        uPaymentUrl={cartState.uPayment}
      />
    </>
  );
};

export default OrderComponent;

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
  title: string;
  confirmLabel: string;
  cancelLabel: string;
  paymenyMethod?: number;
  uPaymentUrl?: string;
};

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  loading,
  title,
  confirmLabel,
  cancelLabel,
  paymenyMethod,
  uPaymentUrl
}: ConfirmDialogProps) => {
  const cartState = useSelector((state: any) => state.cart);
  // console.log('testUpayment', paymenyMethod, uPaymentUrl);
  const cookies = new Cookies();
  const token = cookies.get("token");
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{textAlign: "unset"}} >{title}</DialogTitle>

      <DialogActions>
        {cartState?.payment_method === 4 ? (
          <Link
            to={cartState?.uPayment}
            color="primary"
            autoFocus
            // disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : confirmLabel}
          </Link>
        ) : token ? (
            <Button
              onClick={onConfirm}
              color="primary"
              autoFocus
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : confirmLabel}
            </Button>
          ) : (
            <Link to='/login' 
              color="primary"
              autoFocus
              // disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : confirmLabel}
            </Link>
          )
        }
        {/* <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : confirmLabel}
        </Button> */}
        <Button onClick={onClose} color="error">
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type SubmitButtonProps = {
  onClick: () => void;
  loading: boolean;
  label: string;
};

const SubmitButton = ({ onClick, loading, label }: SubmitButtonProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={loading}
      endIcon={loading && <CircularProgress size={20} />}
      sx={{
        alignSelf: "center",
        backgroundColor: "white",
        color: "#755798",
        textTransform: "none",
        fontSize: {
          xs: "16px",
          sm: "18px",
          md: "22px",
          lg: "28px",
        },
        borderRadius: "12px",
        width: {
          mobile: "160px",
          md: "100%",
        },
        "&:hover": {
          backgroundColor: "#E8D6FF",
        },
      }}
    >
      {label}
    </Button>
  );
};
