import React from "react";
import { Stack, Box, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

type Props = {};

const StyledTypo = styled(Typography)({
  color: "#707070",
  fontSize: "18px",
  "@media(max-width:1024)": {
    fontSize: "14px",
  },
  "@media(max-width:1440px)": {
    fontSize: "16px",
  },
});

const ShoppingCartSummary = ({}: Props) => {
  const { i18n, t } = useTranslation();
  const translationPath = "purchases.purchases-cart.possessions.";
  const cartState = useSelector((state: any) => state.cart);
  const cookies = new Cookies();
  // console.log('test', cartState);
  
  return (
    <Stack
      sx={{
        rowGap: {
          mobile: "12px",
          sm: "16px",
          md: "24px",
          lg: "34px",
        },
        py: "16px",
      }}
    >
      <Stack>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <StyledTypo>{t(`${translationPath}total-products`)}</StyledTypo>
          <StyledTypo>{`${cartState.totalPrice} ${
            i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : (cookies.get("currency_en") || "KWT")
          }`}</StyledTypo>
        </Box>

        {cartState?.dvdPrices > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledTypo>{t(`${translationPath}cover_prices`)}</StyledTypo>
            <StyledTypo>{`${cartState.dvdPrices} ${
              i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : cookies.get("currency_en") || "KWT"
              
            }`}</StyledTypo>
          </Box>
        )}

        {cartState?.offer_discount > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between", color: "red" }}>
            <StyledTypo style={{color: "red"}} >
              {t(`${translationPath}offer_discount`)}
            </StyledTypo>
            <StyledTypo style={{color: "red"}}>{`- ${cartState.offer_discount} ${
              i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : cookies.get("currency_en") || "KWT"
            }`}</StyledTypo>
          </Box>
        )}

        {cartState?.totalStoragesPrice > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledTypo>
              {t(`${translationPath}total_storages_price`)}
            </StyledTypo>
            <StyledTypo>{`${cartState.totalStoragesPrice} ${
              i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : cookies.get("currency_en") || "KWT"
            }`}</StyledTypo>
          </Box>
        )}
        
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <StyledTypo>
            {t(`${translationPath}delivery-shipping-fees`)}
          </StyledTypo>
          <StyledTypo>{`${cartState.shippingPrice} ${
            i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : cookies.get("currency_en") || "KWT"
          }`}</StyledTypo>
        </Box>
        {/* {cartState?.totalDiscountPrice > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledTypo>
              {t(`${translationPath}discount_price`)}
            </StyledTypo>
            <StyledTypo>{`${cartState.totalDiscountPrice} ${
              cookies.get("currency") || "KWT"
            }`}</StyledTypo>
          </Box>
        )} */}
        
        {cartState?.discount > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledTypo style={{color: "red"}}>
              {t(`${translationPath}discount_price`)}
            </StyledTypo>
            <StyledTypo style={{color: "red"}}>{`- ${cartState.discount} ${
              i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : cookies.get("currency_en") || "KWT"
            }`}</StyledTypo>
          </Box>
        )}
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: "#755798",
            fontSize: {
              mobile: "18px",
              md: "20px",
              lg: "24px",
            },
          }}
        >
          {t(`${translationPath}total`)}
        </Typography>
        <Typography
          sx={{
            color: "#755798",
            fontSize: {
              mobile: "18px",
              md: "20px",
              lg: "24px",
            },
          }}
        >
          {`${cartState.totalCost} ${i18n.language === "ar" ? (cookies.get("currency_ar") || "دينار كويتى") : cookies.get("currency_en") || "KWT"}`}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ShoppingCartSummary;
