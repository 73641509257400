import { Box, Button, CircularProgress, Collapse, FormControlLabel, Switch, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import useAddToCart from "../../../Hooks/useAddToCart";
import PriceCard from "../PriceItem/PriceCard";
import QuantityTextfield from "../QuantityTextfield/QuantityTextfield";

type Props = {};

const AddToCartForm = (props: Props) => {
  const navigate = useNavigate();
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1rem",
    backgroundImage: "linear-gradient(198deg, rgba(2,0,36,1) 0%, rgba(90,66,119,1) 100%, rgba(206,175,245,1) 100%)",
    boxShadow: "0px -2px 17px #b966b6c2",
    borderRadius: "30px",
    backdropFilter: "blur(39px)",
    webkitBackdropFilter: "blur(39px)",
    padding: "2rem",
    width: "100%",
    maxHeight: "90vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#3db9ec",
      borderRadius: "40px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "16px 0",
    },
    "@media (max-width: 768px)": {
      minWidth: "90vw",
    },
    "@media (max-width: 560px)": {
      minWidth: "90vw",
      padding: "16px",
    },
  };
  const cookies = new Cookies();

  const { t, i18n } = useTranslation();
  let currency =
    i18n.language === "ar"
      ? cookies.get("currency_ar") || cookies.get("currency_en") || "KWT"
      : cookies.get("currency_en") || cookies.get("currency_ar") || "KWT";

  const items = [
    {
      id: 1,
      title: t("addToCartModal.online"),
      name: "online",
    },
    {
      id: 2,
      title: t("addToCartModal.download"),
      name: "download",
    },
    {
      id: 3,
      title: t("addToCartModal.storage"),
      name: "storage",
    },
    {
      id: 4,
      title: t("addToCartModal.Dvd"),
      name: "Dvd",
      hasCover: false,
      quantity: 1,
    },
  ];

  //getMovie Id from the redux store
  const modalState = useSelector((state: any) => state.addToCart);

  const [showHasCover, setShowHasCover] = useState(false);

  const { handleSubmit, control } = useForm();

  //add to cart logic

  const { loading, handleAddToCart } = useAddToCart();

  const onSubmit = async (data: any) => {
    let priceTypes: any[] = [];
    if (data) {
      data?.price_types?.map((item: any, index: number) => {
        if (item?.name) {
          priceTypes.push({
            type: index + 1,
            quantity: item?.quantity ? item?.quantity : 1,
            has_cover: item?.has_cover ? 1 : 0,
          });
        }
      });
    }

    let formData = new FormData();
    if (modalState?.type === "movie") {
      formData.append("movie_id", modalState.movieId);
    }
    if (modalState?.type === "season") {
      formData.append("season_id", modalState.movieId);
    }
    if (modalState?.type === "episode") {
      formData.append("episode_id", modalState.movieId);
    }
    if (modalState?.type === "series") {
      formData.append("series_id", modalState.movieId);
    }

    // Append priceTypes as an array directly
    priceTypes.forEach((priceType, index) => {
      formData.append(`price_types[${index}][type]`, priceType.type);
      formData.append(`price_types[${index}][quantity]`, priceType.quantity);
      formData.append(`price_types[${index}][has_cover]`, priceType.has_cover);
    });
    handleAddToCart(modalState.type, formData);
    // if (cookies.get("token")) {
    // } else {
    //   navigate("/login");
    // }
  };
  // console.log(modalState);
  //
  
  return (
    <>
      <Box
        component={motion.form}
        sx={containerStyles}
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          sx={{
            color: "#3ab5ff",
            fontSize: {
              mobile: "1.25rem",
              sm: "1.5rem",
              md: "2rem",
            },
            fontWeight: "bold",
            textAlign: "center",
          }}
          variant="h4"
          component="h2"
        >
          {}
          {t("addToCartModal.product")}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
            },
            justifyContent: "space-between",
            columnGap: {
              mobile: "1rem",
              sm: "1.5rem",
              md: "2rem",
            },
            rowGap: "1rem",
          }}
        >
          {items.map((item, index: number) => {
            if (item.name === "Dvd") {
              if (modalState.type === "episode") return;
              return (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    display: "flex",
                    gap: ".5rem",
                    flexDirection: "column",
                  }}
                >
                  <PriceCard
                    id={item.id}
                    title={item.title}
                    name={item.name}
                    control={control}
                    setShowHasCover={setShowHasCover}
                  />
                  <Collapse in={showHasCover}>
                    <QuantityTextfield
                      control={control}
                      label="الكمية"
                      name={`price_types[${item.id - 1}].quantity`}
                      defaultValue={1}
                    />
                  </Collapse>
                  <Collapse in={showHasCover}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Controller
                        name={`price_types[${item.id - 1}].has_cover`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                {...field}
                                color="secondary"
                                // size="small"
                              />
                            }
                            label={`${t("hasCover")}`}
                            sx={{
                              width: "100%",
                              color: "white",
                              flexDirection: "row-reverse",
                              justifyContent: "space-between",
                              margin: "0 .875rem 0rem 0 !important",
                              "& .MuiSwitch-root": {
                                // border: field.value
                                //   ? "1px solid #3ab5ff"
                                //   : "1px solid #fff",
                                borderRadius: "8px",
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                      <Typography
                        sx={{
                          // position: "absolute",
                          // right: "50%",
                          // top: "100%",
                          // transform: "translate(50%, -50%)",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          fontWeight: "bold",
                          fontSize: {
                            mobile: "1rem",
                            md: "1.25rem",
                            lg: "1.5rem",
                          },
                          color: "#3ab5ff",
                          "& span": {
                            fontSize: ".875rem",
                            margin: "0 0.35rem 0 0.25rem",
                          },
                          // right: "auto"
                        }}
                      >
                        {modalState?.price?.dvd?.cover_price}
                        <span>{currency}</span>
                      </Typography>
                  </Collapse>
                </Box>
              );
            } else {
              return (
                <>
                  <PriceCard key={index} id={item.id} title={item.title} name={item.name} control={control} />
                </>
              )
            }
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              width: {
                mobile: "140px",
                md: "150px",
              },
              height: {
                mobile: "40px",
                md: "50px",
              },
              borderRadius: {
                mobile: "6px",
                md: "8px",
              },
              color: "white",
            }}
            disableElevation
            variant="contained"
            color="secondary"
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : t("addToCartModal.addToCart")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddToCartForm;
