import { Box, Typography } from "@mui/material";
import React from "react";
import { movie } from "../../../Types/Types";
import MainCard from "../Card/MainCard";
import MediaListSlider from "../MediaListSlider/MediaListSlider";
import SectionHead from "../SectionHead/SectionHead";
import NotFound from "../NotFound/NotFound";
import { useTranslation } from "react-i18next";

type Props = {
  movies: movie[];
  title: string;
  subTitle?: string;
  color: string;
  actions?: boolean;
  moreButton?: number;
  categoryEnum?: number;
  sliderRef?: any;
  sliderSettings?: any;
  dots?: boolean;
};

const MediaSliderSection = ({
  movies,
  categoryEnum,
  title,
  subTitle,
  color,
  actions,
  moreButton,
  sliderRef,
  sliderSettings,
  dots,
}: Props) => {
  const MovieSlider = MediaListSlider(MainCard);
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box
        component={"section"}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: {
            mobile: "40px",
            sm: "60px",
          },
        }}
      >
        <SectionHead
          color={color}
          title={title}
          subTitle={subTitle}
          actions={actions}
          moreButton={{
            exist: moreButton ? true : false,
            url: "/",
            id: moreButton,
          }}
          categoryEnum={categoryEnum}
          yearFilter={categoryEnum === 6 ? true : false}
        />
        {!movies ||
          (movies?.length === 0 && (
            <NotFound
              text={
                categoryEnum === 6 ? t("noItemsThisYear") : t("noCategories")
              }
              action={false}
              size={"small"}
            />
          ))}
        {movies && movies?.length > 0 && (
          <MovieSlider
            sliderRef={sliderRef}
            items={movies}
            sliderSetting={sliderSettings}
            dots={dots}
            // rtl={i18n.language === 'ar'}
            rtl={false}
          />
        )}
      </Box>
    </>
  );
};

export default React.memo(MediaSliderSection);
