import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AlertMessage.scss";
import { useTranslation } from "react-i18next";

const AlertMessage = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <ToastContainer
        position={"bottom-right"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={i18n.dir() === "rtl" ? true : false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default AlertMessage;
